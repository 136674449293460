import { createDots } from "./createDots";
import { throttle } from "../../../utilities/throttle";
import { getItemToFocus } from "./getItemToFocus";
import { makeDotsInteractive } from "./makeDotsInteractive";
import { observeDots } from "./observe-dots";
import { shiftFocus } from "./shiftFocus";
import { toggleDot } from "./toggleDot";

const handleNavDisplay = (
  currentPageSize,
  itemLength,
  prevButton,
  nextButton,
  nav
) => {
  if (currentPageSize >= itemLength) {
    prevButton.style.display = "none";
    nextButton.style.display = "none";
    nav.style.display = "none";
  } else {
    prevButton.style.display = "block";
    nextButton.style.display = "block";
    nav.style.display = "inherit";
  }
};

export const CarouselNav = (element) => {
  const getCurrentPageSize = (carousel) =>
    parseInt(
      carousel
        .querySelector(".carousel__content__wrapper")
        .classList.value.match(/\d/g)
        .join()
    );

  const carousel = element.parentElement;
  const maxPageSize = getCurrentPageSize(carousel);
  const mobilePageSize = carousel.querySelector(".carousel__content__wrapper")
    .dataset.mobilePageSize;

  let previewNextPagePercentage;

  let nav = carousel.querySelector("nav");
  const carouselContent = carousel.querySelector("ul");
  const carouselItems = Array.from(carouselContent.children);
  const nextButton = carousel.querySelector(".js-next");
  const prevButton = carousel.querySelector(".js-previous");
  let listItemToFocus = carouselContent.children[0];

  const dotColor = nextButton
    ? window
      .getComputedStyle(
        nextButton.firstElementChild
          ? nextButton.firstElementChild
          : nextButton
      )
      .getPropertyValue("background-color")
    : null;

  nextButton &&
    nextButton.addEventListener("click", () => {
      carouselContent.scrollBy(carouselContent.clientWidth, 0);
    });

  prevButton &&
    prevButton.addEventListener("click", () => {
      carouselContent.scrollBy(-carouselContent.clientWidth, 0);
    });

  const handleDot = (entry) => {
    const currentPageSize = getCurrentPageSize(carousel);
    const numberOfPages = Math.ceil(carouselItems.length / currentPageSize);
    const dotIndex = Math.floor(
      carouselItems.indexOf(entry.target) / currentPageSize
    );
    listItemToFocus = getItemToFocus(
      entry,
      carouselContent,
      dotIndex * currentPageSize
    );
    shiftFocus(entry, listItemToFocus, nav);
    if (entry.isIntersecting) toggleDot(nav, dotIndex);
    if (
      entry.isIntersecting &&
      entry.target.querySelector("a") === listItemToFocus
    ) {
      if (dotIndex + 1 === numberOfPages) {
        nextButton.setAttribute("disabled", "");
      } else {
        nextButton.removeAttribute("disabled");
      }
      if (dotIndex === 0) {
        prevButton.setAttribute("disabled", "");
      } else {
        prevButton.removeAttribute("disabled");
      }
    }
  };

  let currentPageSize = maxPageSize;

  const handleNavigation = () => {
    const w = window.innerWidth;
    let newPageSize = maxPageSize;
    const mobilePageSizeOnly = mobilePageSize ? mobilePageSize : 2;

    if (w >= 1280 && maxPageSize > 5) {
      newPageSize = 6;
    } else if (w < 1280 && w >= 1180 && maxPageSize > 4) {
      newPageSize = 5;
    } else if (w < 1180 && w >= 1024 && maxPageSize > 3) {
      newPageSize = 4;
    } else if (w < 1024 && w >= 737 && maxPageSize > 2) {
      newPageSize = 3;
    } else if (w < 737) {
      previewNextPagePercentage = carousel.querySelector(
        ".carousel__content__wrapper"
      ).dataset.previewNext;
      newPageSize = Number(mobilePageSizeOnly) + Number(previewNextPagePercentage);
    }

    const carouselWrapper = carousel.querySelector(
      ".carousel__content__wrapper"
    );

    carouselWrapper.classList.replace(
      `carouselItems-${currentPageSize}`,
      `carouselItems-${newPageSize}`
    );

    currentPageSize = newPageSize;

    nav.remove();
    carousel.insertAdjacentHTML(
      "beforeend",
      createDots(carouselItems, currentPageSize, dotColor)
    );

    nav = carousel.querySelector("ul.nav-dots");

    handleNavDisplay(
      currentPageSize,
      carouselItems.length,
      prevButton,
      nextButton,
      nav
    );

    makeDotsInteractive(nav, carousel.querySelector(".carousel__content"), previewNextPagePercentage, newPageSize);
    observeDots(carouselItems, handleDot);
  };

  window.addEventListener("resize", throttle(handleNavigation));

  handleNavigation();
};
