import debounce from "../../utilities/debounce";
import playPauseTranslations from "../video-hero/utils/play-pause.json";

export const VideoHero = (element) => {
  const { posterprimary, postersecondary, srcprimary, srcsecondary } =
    element.dataset;
  let video;
  let { innerWidth: currentInnerWidth } = window;
  const lang = window.asos.webContext.getBrowseLanguage();

  function insertVideo() {
    video = document.createElement("video");
    video.controls = false;
    video.loop = true;
    video.muted = true;
    video.playsInline = true;

    element.appendChild(video);
  }

  const playPauseButton = document.createElement("button");

  playPauseButton.addEventListener("click", (e) => {
    e.stopImmediatePropagation();
    e.preventDefault();
    if (video.paused) {
      playVideo();
    } else {
      pauseVideo();
    }
  });

  const playVideo = () => {
    const ariaPause = playPauseTranslations[lang].pause;

    const pauseIcon = `
      <span class="icon">
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M21 14H15V30H21V14ZM29 14H23V30H29V14Z" fill="white"/>
          <path d="M15 14V13.5H14.5V14H15ZM21 14H21.5V13.5H21V14ZM15 30H14.5V30.5H15V30ZM21 30V30.5H21.5V30H21ZM23 14V13.5H22.5V14H23ZM29 14H29.5V13.5H29V14ZM23 30H22.5V30.5H23V30ZM29 30V30.5H29.5V30H29ZM15 14.5H21V13.5H15V14.5ZM15.5 30V14H14.5V30H15.5ZM21 29.5H15V30.5H21V29.5ZM20.5 14V30H21.5V14H20.5ZM23 14.5H29V13.5H23V14.5ZM23.5 30V14H22.5V30H23.5ZM29 29.5H23V30.5H29V29.5ZM28.5 14V30H29.5V14H28.5Z" fill="#767676"/>
        </svg>
      </span>
      `;

    video.play();
    playPauseButton.innerHTML = pauseIcon;
    playPauseButton.ariaLabel = ariaPause;
  };

  const pauseVideo = () => {
    const ariaPlay = playPauseTranslations[lang].play;

    const playIcon = `
      <span class="icon">
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16 14L31 21.9412L16 30L16 14Z" fill="white" stroke="#767676"/>
        </svg>
      </span>
      `;

    video.pause();
    playPauseButton.innerHTML = playIcon;
    playPauseButton.ariaLabel = ariaPlay;
  };

  /*
      we'll uncomment the sound button once we are getting video sound attribute from sitecore to 
      mute/unmute video.
    */

  // function soundButton() {
  //   const soundButton = document.createElement("button");
  //   soundButton.style.pointerEvents = "none";

  //   const ariaUnmute = muteUnmuteTranslations[lang].unmute;

  //   const unmuteIcon = `
  //   <span class="icon icon-mute">
  //     </span>
  //   `;

  //   soundButton.innerHTML = unmuteIcon;
  //   soundButton.ariaLabel = ariaUnmute;

  //   const muteIcon = `
  //     <span class="icon icon-unmute">
  //     </span>
  //     `;

  //   soundButton.addEventListener("click", () => {
  //     if (video.muted) {
  //       video.muted = false;
  //       soundButton.innerHTML = muteIcon;
  //       soundButton.ariaLabel = "Mute";
  //     } else {
  //       video.muted = true;
  //       soundButton.innerHTML = unmuteIcon;
  //       soundButton.ariaLabel = "Unmute";
  //     }
  //   });

  //   return soundButton;
  // }

  function customVideoControls() {
    const buttonContainer = document.createElement("div");

    buttonContainer.classList.add("control-button-overlay");
    buttonContainer.appendChild(playPauseButton);

    element.appendChild(buttonContainer);
  }

  function setPosterAndSource(wasPaused) {
    const { innerWidth } = window;

    const isMobile = innerWidth < 767;
    const src = isMobile && srcsecondary ? srcsecondary : srcprimary;
    const poster =
      isMobile && postersecondary ? postersecondary : posterprimary;

    if (poster) video.poster = poster;
    if (src) video.src = src;
    if (wasPaused) pauseVideo();
    else playVideo();
  }

  function handleResize() {
    const { innerWidth } = window;
    if (currentInnerWidth !== innerWidth) {
      setPosterAndSource(video.paused);
      currentInnerWidth = innerWidth;
    }
  }

  function canAutoPlay() {
    const prefersReducedMotion = window.matchMedia(
      "(prefers-reduced-motion: reduce)"
    ).matches;
    const isChromium = !!window.chrome;

    if (prefersReducedMotion) return false;
    if (
      isChromium &&
      (navigator.connection.saveData ||
        navigator.connection.effectiveType === "2g")
    )
      return false;

    return true;
  }

  window.addEventListener("resize", debounce(handleResize));

  insertVideo();
  setPosterAndSource();
  customVideoControls();

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (canAutoPlay()) {
            playVideo();
          }
        } else {
          pauseVideo();
        }
      });
    },
    {
      rootMargin: "0px",
    }
  );

  observer.observe(element);
};
