export const hide = (status) => {
  status.visible = false;
  status.button.setAttribute("aria-expanded", "false");
  status.panel.setAttribute("aria-hidden", "true");
  status.button.classList.remove("open");
  status.panel.style.height = 0;

  setTimeout(() => {
    status.panel.style.visibility = "hidden";
  }, 600);
};
