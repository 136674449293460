import { show } from "./utils/showAccordion";
import { hide } from "./utils/hideAccordion";
import { preparePanelHeight } from "./utils/preparePanelHeight";

export const Accordion = (element) => {
  const status = {
    visible: false,
    animating: false,
    panel: element.nextElementSibling,
    button: element.querySelector("button"),
  };

  status.panel.setAttribute("style", "display: block; visibility: hidden;");

  const headerClick = (event) => {
    event.preventDefault();
    status.visible ? hide(status) : show(status);
  };

  preparePanelHeight(status);

  element.addEventListener("click", headerClick);
};
